import {Platform} from 'react-native';
import {COUNTRY_AUSTRALIA, COUNTRY_CANADA, COUNTRY_SINGAPORE, COUNTRY_UAE, COUNTRY_UK, COUNTRY_USA} from './countryCode';
import {COUNTRY_AUSTRALIA_CUR, COUNTRY_CANADA_CUR, COUNTRY_SINGAPORE_CUR, COUNTRY_UAE_CUR, COUNTRY_UK_CUR, COUNTRY_USA_CUR} from './countryDisbCurrency';
import { DOMAIN_URL_NON_US, DOMAIN_URL_SINGAPORE, DOMAIN_URL_US, DOMAIN_URL } from './domainUrl';
import { Buffer } from 'buffer';

const crypto = require('./crypto');

export const makeSessionId = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const checkLN = res => {
  let value;
  if ((res && res.LNSTATUS === 'SUCCESS') || (res && res.LNSTATUS === 'LN Success Manual')) {
    if (res && res.EQUIFAXSTATUS === 'Y') {
      value = false;
    } else {
      value = false;
    }
  } else {
    value = true;
  }
  return value;
};

export const urlCheck = (domainUrl) => {
  if (DOMAIN_URL === domainUrl) return true;
  return false;
}

export const encrypt = (data, key, iv) => {
  const decodeKey = crypto
    .createHash('sha256')
    .update(key, 'utf-8')
    .digest();
  const cipher = crypto.createCipheriv(
    'aes-256-cbc',
    decodeKey,
    new Buffer.from(iv)
  );
  return cipher.update(data, 'utf8', 'base64') + cipher.final('base64');
};

export const decrypt = (data, key, iv) => {
  const encodeKey = crypto
    .createHash('sha256')
    .update(key, 'utf-8')
    .digest();
  const cipher = crypto.createDecipheriv(
    'aes-256-cbc',
    encodeKey,
    new Buffer.from(iv)
  );
  return cipher.update(data, 'base64', 'utf8') + cipher.final('utf8');
};

export const getAllUrlParams = url => {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  const obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      const paramName = a[0];
      const paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      // paramName = paramName.toLowerCase();
      // if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};

export const getUrlSSOVars = queryString => {
  let obj = {};
  const urlParams = new URLSearchParams(queryString);

  const type = urlParams.get('type');
  obj.type = type;
  const leadId = urlParams.get('leadId');

  obj.leadId = leadId;
  return obj;
};

export const getUrlSSOHomeVars = queryString => {
  let obj = {};
  const urlParams = new URLSearchParams(queryString);

  const leadId = urlParams.get('leadId');
  const instanceId = urlParams.get('instanceId');
  obj.leadId = leadId;
  obj.instanceId = instanceId;
  return obj;
};

export const isEmpty = obj => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const filterUtmCodes = utmArray => {
  let newUtmArray = [];
  if (utmArray === undefined || isEmpty(utmArray)) {
    if (Platform.os !== 'web' || utmArray.length === 0) {
      newUtmArray.utm_source = '';
      newUtmArray.utm_medium = '';
      newUtmArray.utm_term = '';
      newUtmArray.utm_content = '';
      newUtmArray.utm_campaign = '';
      newUtmArray.utm_adgroup = '';
    }
  } else {
    newUtmArray = utmArray;
  }
  return newUtmArray;
};
export const getDomainURL = countryId => {
  let domainUrl = '';
  switch (countryId) {
    case COUNTRY_USA:
      domainUrl = DOMAIN_URL_US;
      break;
    case COUNTRY_AUSTRALIA:
      domainUrl = DOMAIN_URL_NON_US;
      break;
    case COUNTRY_CANADA:
      domainUrl = DOMAIN_URL_NON_US;
      break;
    case COUNTRY_UAE:
      domainUrl = DOMAIN_URL_NON_US;
      break;
    case COUNTRY_UK:
      domainUrl = DOMAIN_URL_NON_US;
      break;
    case COUNTRY_SINGAPORE:
      domainUrl = DOMAIN_URL_SINGAPORE;
      break;
    default:
      domainUrl = DOMAIN_URL_US;
  }
  return domainUrl;
};

export const getDisbCurrency = countryId => {
  let disbCurrency = '';
  switch (countryId) {
    case COUNTRY_USA:
      disbCurrency = COUNTRY_USA_CUR;
      break;
    case COUNTRY_AUSTRALIA:
      disbCurrency = COUNTRY_AUSTRALIA_CUR;
      break;
    case COUNTRY_CANADA:
      disbCurrency = COUNTRY_CANADA_CUR;
      break;
    case COUNTRY_UAE:
      disbCurrency = COUNTRY_UAE_CUR;
      break;
    case COUNTRY_UK:
      disbCurrency = COUNTRY_UK_CUR;
      break;
    case COUNTRY_SINGAPORE:
      disbCurrency = COUNTRY_SINGAPORE_CUR;
      break;
    default:
      disbCurrency = COUNTRY_USA_CUR;
  }
  return disbCurrency;
};

export const getCurrencyValue = countryId => {
  let currencyValue = '';
  switch (countryId) {
    case COUNTRY_USA:
      currencyValue = 'usd-to-inr';
      break;
    case COUNTRY_AUSTRALIA:
      currencyValue = 'aud-to-inr';
      break;
    case COUNTRY_CANADA:
      currencyValue = 'cad-to-inr';
      break;
    case COUNTRY_UAE:
      currencyValue = 'aed-to-inr';
      break;
    case COUNTRY_UK:
      currencyValue = 'gbp-to-inr';
      break;
    case COUNTRY_SINGAPORE:
      currencyValue = 'sgd-to-inr';
      break;
    default:
      currencyValue = 'usd-to-inr';
  }
  return currencyValue;
};

export const getCountryNameValue = countryId => {
  let countryNameValue = '';
  switch (countryId) {
    case COUNTRY_USA:
      countryNameValue = 'usa';
      break;
    case COUNTRY_AUSTRALIA:
      countryNameValue = 'aus';
      break;
    case COUNTRY_CANADA:
      countryNameValue = 'canada';
      break;
    case COUNTRY_UAE:
      countryNameValue = 'uae';
      break;
    case COUNTRY_UK:
      countryNameValue = 'uk';
      break;
    case COUNTRY_SINGAPORE:
      countryNameValue = 'sin';
      break;
    default:
      countryNameValue = 'usa';
  }
  return countryNameValue;
};

export const getInrFormat = amt => {
  const amount = Number(amt).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
  return amount;
}

export const getUsdFormat = amt => {
  const amount = Number(amt).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return amount;
}
