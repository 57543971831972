/* eslint-disable no-array-constructor */
/* eslint-disable eqeqeq */
var END_OF_INPUT = -1;
var secEncrypt = '';
var base64Chars = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '/');

var reverseBase64Chars = new Array();
for (var i = 0; i < base64Chars.length; i++) {
  reverseBase64Chars[base64Chars[i]] = i;
}

var base64Str;
var base64Count;
function setBase64Str(str) {
  base64Str = str;
  base64Count = 0;
}
function readBase64() {
  if (!base64Str) return END_OF_INPUT;
  if (base64Count >= base64Str.length) return END_OF_INPUT;
  var c = base64Str.charCodeAt(base64Count) & 0xff;
  base64Count++;
  return c;
}
export function encodeBase64(str) {
  setBase64Str(str);
  var result = '';
  var inBuffer = new Array(3);
  var lineCount = 0;
  var done = false;
  while (!done && (inBuffer[0] = readBase64()) != END_OF_INPUT) {
    inBuffer[1] = readBase64();
    inBuffer[2] = readBase64();
    result += base64Chars[inBuffer[0] >> 2];
    if (inBuffer[1] != END_OF_INPUT) {
      result += base64Chars[((inBuffer[0] << 4) & 0x30) | (inBuffer[1] >> 4)];
      if (inBuffer[2] != END_OF_INPUT) {
        result += base64Chars[((inBuffer[1] << 2) & 0x3c) | (inBuffer[2] >> 6)];
        result += base64Chars[inBuffer[2] & 0x3f];
      } else {
        result += base64Chars[(inBuffer[1] << 2) & 0x3c];
        result += '=';
        done = true;
      }
    } else {
      result += base64Chars[(inBuffer[0] << 4) & 0x30];
      result += '=';
      result += '=';
      done = true;
    }
    lineCount += 4;
    if (lineCount >= 76) {
      result += '\n';
      lineCount = 0;
    }
  }

  secEncrypt = DoChange(result, 3);
  return secEncrypt;
}

//Caesar Encryption
function DoEncrypt(x, shf) {
  let abc = 'abcdefghijklmnopqrstuvwxyz';
  let ABC = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let r1 = '';
  let r2 = '';
  shf = eval(shf);
  for (i = 0; i < x.length; i++) {
    let y = x.charAt(i);
    let pos = ABC.indexOf(y);
    if (pos >= 0) {
      r1 += ABC.charAt((pos + shf) % 26);
    } else {
      r1 += y;
    }
  }
  for (i = 0; i < r1.length; i++) {
    let y = r1.charAt(i);
    let pos = abc.indexOf(y);
    if (pos >= 0) {
      r2 += abc.charAt((pos + shf) % 26);
    } else {
      r2 += y;
    }
  }
  return r2;
}

function DoChange(x, shf) {
  return DoEncrypt(x, 26 - shf);
}
//End

function ntos(n) {
  n = n.toString(16);
  if (n.length == 1) n = '0' + n;
  n = '%' + n;
  return unescape(n);
}

function pad(str, len, pad) {
  var result = str;
  for (var i = str.length; i < len; i++) {
    result = pad + result;
  }
  return result;
}
