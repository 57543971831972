module.exports = {
  DEFAULT_RECEIVED_AMMOUNT: 150000,
  key1: '1@0m!',
  CSRF_PRE_LOGIN_SERVICE: 1,
  CSRF_LOGIN_SERVICE: 2,
  CSRF_HOME_SSO_SERVICE: 3,
  PRE_FORGOT_PASSWORD: 1,
  FORGOT_PASSWORD: 2,
  iv2: '>t1#',
  LOYALTY_REWARDS: 0,
  PROMO_CODE_SELECTION: 1,
  ONLY_PROMO_CODE_SELECTION: 0,
  APP_TIMEOUT: 1000 * 60 * 10,
  DEBOUNCE_TIMEOUT: 250,
  SEARCH_FREQUENCY: 2,
  TOAST_DURATION: 10000,
  COUNTRY_ID: '751477',  
  key3: 'H&)8',
};
