import PropTypes from 'prop-types';
import React from 'react';
import {Route, withRouter} from 'react-router-dom';
import {ModalRoute} from 'react-router-modal';
import 'react-router-modal/css/react-router-modal.css';
import {getURLPathname} from '../appUtils';

function Wrapper({element, history, match, routeMap, closeModal}) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, '');
    // if the route is not a modal
    // eslint-disable-next-line no-undef
    if (!url.includes('logout') && !url.includes('individual') && !url.includes('business') && !url.includes('login')) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'NewVirtualPageview',
        Page: `VPV/M2I${url}` // replace with page path
      });
    }

    if (!routeMap[to].modal) {
      history.push(url, params);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? '' : '/';
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, '');

      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return (history && history.location && history.location.state && history.location.state[param]) || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: {navigate, getParam, goBack},
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const RoutesGenerator = ({routeMap}) => {
  //browserHistory.replace();
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;

    if (currentRoute.modal) {
      return <ModalRoute key={currentRoute.path} path={currentRoute.path} component={props => <Wrapper element={<Component />} {...props} routeMap={routeMap} />} />;
    }
    return <Route key={currentRoute.path} path={currentRoute.path} exact={currentRoute.exact} render={props => <Wrapper element={<Component />} {...props} routeMap={routeMap} />} />;
  });
};

RoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default RoutesGenerator;
