import React from 'react';
import {Text, Platform} from 'react-native';

const upadateFirebaseEvent = props => {
  if (props.nativeID) {
  }
  // console.log('firbase button', props);
  if (props.onPress) {
    setTimeout(() => {
      props.onPress();
    }, 70);
  }
};
export default function CustomText(props) {
  const {style, ...others} = props;
  let text;
  if (props.onPress) {
    text = (
      <Text
        {...others}
        style={Platform.OS === 'web' 
          ? [{fontFamily: 'Mulish', outlineColor: 'transparent'}, style] 
          : [{fontFamily: 'Mulish'}, style]
        }
        onPress={() => upadateFirebaseEvent(props)}
      ></Text>
    );
  } else {
    text = (
      <Text
        {...others}
        style={
          Platform.OS === 'web' 
            ? [{fontFamily: 'Mulish', outlineColor: 'transparent'}, style] 
            : [{fontFamily: 'Mulish'}, style]
          }
      ></Text>
    );
  }
  return text;
}
