import SecureLS from 'secure-ls';
import {secKey} from '../App';
import {PREF_AUTH_TOKEN} from './preferenceStorage';

import {log} from 'util';
// eslint-disable-next-line import/prefer-default-export
const ls = new SecureLS({
  encodingType: 'des',
  //isCompression: false,
  encryptionSecret: ''
});
export const setCustomStorage = (key, value) => {
  try {
    ls.set(key, {data: value});
    // logMe('StorageUtils', `Saved data; key: ${key}, value: ${value}`);
  } catch (e) {
    // saving error
  }
};

export const getCustomStorage = (key, defaultValue) => {
  try {
    const value = ls.get(key).data;
    return value;
  } catch (e) {
    // error reading value
    return defaultValue;
  }
};

export const removeStorageData = async key => {
  try {
    ls.remove(key);
  } catch (e) {
    // error removing value
  }
};

export const getSecureAuthToken = async () => {
  try {
    const value = ls.get(PREF_AUTH_TOKEN).data;
    return value;
  } catch (e) {
    // error reading value
  }
};
